

import { mapGetters, mapActions } from 'vuex';
// import FsLightbox from "fslightbox-vue";
// import discountCardNew from '@/modules/medical-discounts/components/medical-discounts-card-new.vue'

export default {
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      rows: 'RestaurantsDiscounts/view/rows',
      current: 'branch/view/branch',
      loading: 'RestaurantsDiscounts/view/loading',
      pagination: 'RestaurantsDiscounts/view/pagination',
    }),
    isDark() {
      return this.$q.dark.isActive;
    },
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
    paginationPage() {
      return this.page - 1 || 0;
    },
  },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,
      action: '',
    }
  },
  components: {
    // FsLightbox,
    // discountCardNew,
  },
  methods: {
    next() {
      this.page++;
      this.action = 'next';
    },
    prev() {
      this.page--;
      this.action = 'prev';
    },

    ...mapActions({
      doFetch: 'RestaurantsDiscounts/view/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  watch: {
    
     current() {
      this.page = 1
       this.doFetch({
        branchId: this.current,
        type: "restaurant",
        action: "next",
        currentPage: 0,
      });

     
    },
    async page() {
      this.$router.push({
        path: 'restaurants-discounts',
        query: { page: this.page },
      });
      await this.doFetch({
        branchId: this.current,
        type: 'restaurant',
        action: this.action,
        currentPage: this.paginationPage,
      });
    },
    '$route.params.page': {
      handler: function () {
        this.page = this.$route.query.page || 1
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    await this.doFetch({
      branchId: this.current,
      type: 'restaurant',
      action: 'next',
      currentPage: this.paginationPage,
    });

  },
};
